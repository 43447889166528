<script>
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'
import MainBackground from '@/assets/images/004.jpg'

import { modifyUserInfo, modifyPasswordInfo } from '@/api/auth'

import LanguageConverter from '@/util/LanguageConverter'

export default {
  components: { ImageBackgroundVue },
  data() {
    const user = this.$store.state.userStore.info
    const src = MainBackground

    return {
      user,
      src
    }
  },
  methods: {
    LanguageConverter,
    showModifyInput(e) {
      const target = e.target

      const inputList = target.closest('.wf__my-page-item').querySelectorAll('.wf__my-page-item-input')
      for (const input of inputList) {
        input.classList.toggle('hide')
        input.querySelector('input').value = ''
      }
    },

    confirmModify(e) {
      const target = e.target
      const targetInputName = target.dataset.targetInput
      const targetInput = this.$refs.form[targetInputName]

      if (targetInputName === 'password') return this.modifyPassword(targetInput)
      return this.modifyInfo(targetInput, targetInputName)
    },

    async modifyPassword(passwordList) {
      const [password, newPassword] = passwordList
      const param = {
        password: password.value,
        newPassword: newPassword.value
      }
      const result = await modifyPasswordInfo(param)

      if (result.status === 401 && result.data.message === 'jwt expired') {
        alert('로그인 유효 시간이 만료되었습니다. 다시 로그인해주세요')
        return this.$router.replace('/member/login')
      }

      alert('변경되었습니다.')
      return this.$router.go()
    },

    async modifyInfo(input, inputName) {
      const param = {}
      param[inputName] = input.value
      const result = await modifyUserInfo(param)

      if (result.status === 401 && result.data.message === 'jwt expired') {
        alert('로그인 유효 시간이 만료되었습니다. 다시 로그인해주세요')
        return this.$router.replace('/member/login')
      }

      if (result.code === 'ER_DUP_ENTRY') {
        return alert('이미 존재하는 이메일입니다.')
      }

      if (result.code) {
        return alert(result.message)
      }

      alert('변경되었습니다.')
      return this.$router.go()
    },
    async toogleAgreement(e) {
      const agreement = e.target.checked ? 1 : 0

      setTimeout(() => {
        this.modifyInfo({ value: agreement }, 'advertisementAgreement')
      }, 400)
    }
  },
  mounted() {}
}
</script>

<template>
  <ImageBackgroundVue class="background-img" :src="src">
    <div class="wf__default-title">{{ LanguageConverter({ en: 'MyPage', kr: '마이페이지' }) }}</div>
  </ImageBackgroundVue>

  <form class="wf__mypage" ref="form">
    <div>
      <h2 class="wf__mypage-title">{{ LanguageConverter({ en: 'Info', kr: '기본정보' }) }}</h2>
      <div class="wf__mypage-wrapper">
        <div class="wf__my-page-item">
          <div class="wf__my-page-item-view">
            <span class="wf__mypage-name">{{ user.name }}</span>
            <button type="button" class="wf__my-page-modify-btn" @click="showModifyInput">
              {{ LanguageConverter({ en: 'Mod', kr: '수정' }) }}
            </button>
          </div>

          <div class="wf__my-page-item-input hide">
            <input class="wf__my-page-modify-input" name="name" type="text" placeholder="" />
            <button type="button" class="wf__my-page-modify-btn" data-target-input="name" @click="confirmModify">
              {{ LanguageConverter({ en: 'Confirm', kr: '확인' }) }}
            </button>
          </div>
        </div>

        <div class="wf__my-page-item">
          <div class="wf__my-page-item-view">
            <span class="wf__my-page-icon-wrapper">
              <span class="material-icons-outlined"> mail </span>
              {{ user.email }}
            </span>
            <button type="button" class="wf__my-page-modify-btn" @click="showModifyInput">
              {{ LanguageConverter({ en: 'Mod', kr: '수정' }) }}
            </button>
          </div>

          <div class="wf__my-page-item-input hide">
            <input class="wf__my-page-modify-input" name="email" type="text" placeholder="" />
            <button type="button" class="wf__my-page-modify-btn" data-target-input="email" @click="confirmModify">
              {{ LanguageConverter({ en: 'Confirm', kr: '확인' }) }}
            </button>
          </div>
        </div>

        <div class="wf__my-page-item">
          <div class="wf__my-page-item-view">
            <span class="wf__my-page-icon-wrapper">
              <span class="material-icons-outlined"> lock </span>
            </span>
            <button type="button" class="wf__my-page-modify-btn" @click="showModifyInput">
              {{ LanguageConverter({ en: 'Change Password', kr: '비밀번호 변경' }) }}
            </button>
          </div>

          <div class="wf__my-page-item-input hide">
            <input class="wf__my-page-modify-input" name="password" type="password" placeholder="현재 비밀번호" />
          </div>
          <div class="wf__my-page-item-input hide">
            <input class="wf__my-page-modify-input" name="password" type="password" placeholder="새 비밀번호" />
            <button type="button" class="wf__my-page-modify-btn" data-target-input="password" @click="confirmModify">
              {{ LanguageConverter({ en: 'Confirm', kr: '확인' }) }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h2 class="wf__mypage-title">{{ LanguageConverter({ en: 'Promotion Agreements', kr: '프로모션 정보수신 동의' }) }}</h2>
      <div class="wf__mypage-wrapper">
        <div class="wf__my-page-item">
          <div class="wf__my-page-item-view">
            <span class="wf__my-page-icon-wrapper">
              <span class="material-icons-outlined"> mail </span>
              {{ LanguageConverter({ en: 'E-mail', kr: '이메일' }) }}
            </span>

            <label class="switch">
              <input type="checkbox" :checked="user.advertisementAgreement" @click="toogleAgreement" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.background-img {
  min-width: 100vw;
  margin-left: calc(-50vw + 50%);
}
.wf__mypage {
  text-align: left;
  width: 694px;

  margin: 0 auto;
  margin-top: 3em;
  padding-bottom: 3em;

  display: flex;
  flex-direction: column;
  gap: 1.5em;

  font-size: var(--wf-text-16);
}
.wf__mypage-title {
  font-size: var(--wf-text-30);
  font-weight: bold;
  margin-bottom: 0.75em;
}
.wf__mypage-wrapper {
  border: 1px solid var(--wf-gray-color);
  border-radius: 0.5em;

  padding: 0.5em 1em;
}
.wf__my-page-item {
  padding: 0.75em 0.5em;
  border-bottom: 1px solid var(--wf-gray-500);

  display: flex;
  flex-direction: column;
  gap: 1em;
}
.wf__my-page-item-view,
.wf__my-page-item-input {
  display: flex;
  justify-content: space-between;
}
.wf__my-page-item-input.hide {
  display: none;
}

.wf__my-page-modify-input {
  border: 1px solid var(--wf-gray-500);
  border-radius: 0.25em;
  flex-basis: 20em;

  padding: 0.5em 0.75em;
}

.wf__mypage-wrapper .wf__my-page-item:last-child {
  border: none;
}
.wf__my-page-icon-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.wf__mypage-name {
  font-weight: bold;
  font-size: var(--wf-text-24);
}

.wf__my-page-modify-btn {
  border: 1px solid var(--wf-gray-500);
  border-radius: 5px;
  outline: none;
  background-color: var(--wf-gray-600);
  color: var(--wf-secondary-color);

  height: 2em;

  padding: 0em 0.5em;
}

.wf__my-page-modify-btn:hover {
  opacity: 0.75;
}

.switch {
  position: relative;
  display: inline-block;
  width: 4em;
  height: 2em;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--wf-gray-color);
  transition: 0.4s;

  display: flex;
  align-items: center;
}
.slider:before {
  position: absolute;
  content: '';
  height: 1.5em;
  width: 1.5em;
  left: 0.25em;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: var(--wf-primary-color);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--wf-primary-color);
}
input:checked + .slider:before {
  left: calc(4em - 1.5em - 0.25em);
}

/* Rounded sliders */
.slider.round {
  border-radius: 2em;
}
.slider.round:before {
  border-radius: 50%;
}
</style>
